<template>
    <div>
        <!-- 面包屑导航 -->
        <Breadcrumb :breadList="breadList">
            <a-button class="add-bt" type="primary" @click="$utils.linkTo('/shop/order/freightTempEdit')">
                新建运费模板</a-button>
        </Breadcrumb>
        <a-table :data-source="list" bordered :rowKey="(record, idx) => idx" @change="currentChange" :columns="columns"
            :pagination="pagination" :scroll="{ x: 1500 }">
            <span slot="priceAreas" slot-scope="text, record">
                {{ record.priceAreas[0].areaNames }}
            </span>
            <span slot="first" slot-scope="text, record">
                {{ record.priceAreas[0].first
                }}{{ record.way === 1 ? "kg" : record.way === 2 ? "个" : "无匹类型" }}
            </span>
            <span slot="firstPrice" slot-scope="text, record" style="color: red">
                {{ record.priceAreas[0].firstPrice }}
            </span>
            <span slot="dadd" slot-scope="text, record">
                {{ record.priceAreas[0].dadd
                }}{{ record.way === 1 ? "kg" : record.way === 2 ? "个" : "无匹类型" }}
            </span>
            <span slot="addPrice" slot-scope="text, record" style="color: red">
                {{ record.priceAreas[0].addPrice }}
            </span>
            <!-- <span slot="action" slot-scope="text,record">
                <a-spin :spinning="isLoading">
                    <a style="margin-right:20px;"
                        @click="editFreightTemp(record.id)">编辑</a>
                    <a style="margin-right:20px;" @click='deleteFreightTemp(record.id)' href="javascript:;">删除</a>
                </a-spin>
            </span> -->
        </a-table>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: '订单管理',
          url: ''
        },
        {
          name: '运费模板',
          url: ''
        }
      ],
      visible: false,
      isQueryLoading: false,
      isLoading: false,
      formRefuse: {
        id: '',
        failReason: ''
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'name',
          customRender: (value, row, index) => {
            const obj = {
              children: <a href="javascript:;">{value}</a>,
              attrs: {}
            }
            let idNum = this.getRowSpanNum(index).idNum
            let indexOfNum = this.getRowSpanNum(index).indexOfNum
            if (idNum > 1) {
              obj.attrs.rowSpan = indexOfNum === 0 ? idNum : 0
            }
            return obj
          }
        },
        {
          title: '类型',
          dataIndex: 'way',
          customRender: (value, row, index) => {
            const obj = {
              children: (
                <a href="javascript:;">
                  {value === 1
                    ? '按重量计费'
                    : value === 2
                      ? '按件数计费'
                      : '无匹类型'}
                </a>
              ),
              attrs: {}
            }
            let idNum = this.getRowSpanNum(index).idNum
            let indexOfNum = this.getRowSpanNum(index).indexOfNum
            if (idNum > 1) {
              obj.attrs.rowSpan = indexOfNum === 0 ? idNum : 0
            }
            return obj
          },
          key: 'way'
        },
        {
          title: '是否默认',
          dataIndex: 'defaultDelivery',
          key: 'defaultDelivery',
          customRender: (value, row, index) => {
            const obj = {
              children: (
                <a href="javascript:;">
                  {value === 0 ? '否' : value === 1 ? '是' : '无匹类型'}
                </a>
              ),
              attrs: {}
            }
            let idNum = this.getRowSpanNum(index).idNum
            let indexOfNum = this.getRowSpanNum(index).indexOfNum
            if (idNum > 1) {
              obj.attrs.rowSpan = indexOfNum === 0 ? idNum : 0
            }
            return obj
          }
        },
        {
          title: '包邮（元）',
          dataIndex: 'postage',
          key: 'postage',
          customRender: (value, row, index) => {
            const obj = {
              children: (
                <span style="color:red;">
                  {value === 1 ? row.conditions : ''}
                </span>
              ),
              attrs: {}
            }
            let idNum = this.getRowSpanNum(index).idNum
            let indexOfNum = this.getRowSpanNum(index).indexOfNum
            if (idNum > 1) {
              obj.attrs.rowSpan = indexOfNum === 0 ? idNum : 0
            }
            return obj
          }
        },
        {
          title: '配送区域',
          width: 300,
          dataIndex: 'priceAreas',
          key: 'priceAreas',
          scopedSlots: { customRender: 'priceAreas' }
        },
        {
          title: '首重（kg）/首件（个）',
          dataIndex: 'first',
          key: 'first',
          scopedSlots: { customRender: 'first' }
        },
        {
          title: '运费（元）',
          dataIndex: 'firstPrice',
          key: 'firstPrice',
          scopedSlots: { customRender: 'firstPrice' }
        },
        {
          title: '续重（kg）/续件（个）',
          dataIndex: 'dadd',
          key: 'dadd',
          scopedSlots: { customRender: 'dadd' }
        },
        // 土土土土土土土
        {
          title: '续费（元）',
          dataIndex: 'addPrice',
          key: 'addPrice',
          scopedSlots: { customRender: 'addPrice' }
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          key: 'action',
          customRender: (value, row, index) => {
            const preventPopEdit = function (id, e) {
              e.preventDefault()
              this.editFreightTemp(id)
            }
            const preventPopDelete = function (id, e) {
              e.preventDefault()
              this.deleteFreightTemp(id)
            }
            const obj = {
              children: (
                <a-spin spinning={this.isLoading}>
                  <a href="#" style={{ marginRight: '20px' }} onClick={preventPopEdit.bind(this, row.id)}>
                                        编辑
                  </a>
                  <a
                    href="#"
                    onClick={preventPopDelete.bind(this, row.id)}
                  >
                                        删除
                  </a>
                </a-spin>
              ),
              attrs: {}
            }
            let idNum = this.getRowSpanNum(index).idNum
            let indexOfNum = this.getRowSpanNum(index).indexOfNum
            if (idNum > 1) {
              obj.attrs.rowSpan = indexOfNum === 0 ? idNum : 0
            }
            return obj
          }

          // scopedSlots: { customRender: 'action' }
        }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) =>
                    `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      list: [],
      selectedRowKeys: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    getRowSpanNum (index) {
      let idNum = 0
      let indexOfNum = 0
      this.list.forEach((item) => {
        if (item.id === this.list[index].id) {
          idNum++
        }
      })
      for (let idx = 0; idx < index; idx++) {
        if (this.list[idx].id === this.list[index].id) {
          indexOfNum++
        }
      }
      return { idNum, indexOfNum }
    },
    editFreightTemp (id) {
      this.$utils.linkTo(`/shop/order/freightTempEdit?id=${id}`)
    },
    deleteFreightTemp (id) {
      this.$store.dispatch('showConfirm', {
        message: '您确定要删除吗？',
        yes: async (_) => {
          this.isLoading = true
          await this.$store.dispatch('http', {
            api: 'freightTempDelete',
            query: {
              id: id
            },
            success: () => {
              this.$store.dispatch('showToast', { message: '删除成功' })
              this.loadList()
            },
            error: () => {
              this.$store.dispatch('showToast', {
                type: 'error',
                message: '出错了'
              })
            },
            complete: () => {
              this.isLoading = false
            }
          })
        }
      })
    },
    resetForm (formName) {
      this.form = {}
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        // this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      console.log(this.form)
      this.isTableLoading = true
      const params = Object.assign(
        {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize
        },
        this.form
      )
      const { total, records } = await this.$store.dispatch('http', {
        api: 'freightTempList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = this.splitPriceAreas(records)
    },
    splitPriceAreas (records) {
      let newList = []
      if (!records || records.length.length === 0) {
        return newList
      }
      records.forEach((item, index) => {
        if (item.priceAreas && item.priceAreas.length === 1) {
          newList.push(item)
        } else {
          item.priceAreas.forEach((itemAreas, indexAreas) => {
            let newItem = this.$utils.clone(item)
            newItem.priceAreas = []
            newItem.priceAreas.push(itemAreas)
            newList.push(newItem)
          })
        }
      })
      return newList
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    },
    // 选中事件
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style lang="scss" scoped>
.toolTip {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tips {
    color: rgba(24, 144, 255, 1);
    background: rgba(230, 247, 255, 1);
    padding: 10px 50px;
    border: 1px solid rgb(215, 231, 247);
    margin-top: 10px;

    :first-child {
        font-weight: bold;
        font-size: 20px;
    }
}
</style>
